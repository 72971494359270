<template>
  <v-container fluid>
    <v-layout wrap>
      <v-flex xs12>
        <v-card elevation="2">
          <v-card-text>
            <h1><v-icon class="iconTitle">mdi-file-document-multiple</v-icon> Documentación</h1><br>
            <v-layout>
              <v-flex xs12>
                <v-text-field
                  v-model="searchDoc"
                  label="Escriba para buscar un documento"
                  prepend-inner-icon="mdi-magnify"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs1>
                <v-btn v-on:click="newDoc()" text class="btnMini">
                  <v-icon large>mdi-plus</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-list two-line subheader>
      <v-list-item
        v-for="item in filteredItemsDoc"
        :key="item.title"
      >
        <v-list-item-avatar>
          <v-icon
            :class="[item.iconClass]"
            v-text="item.icon"
          ></v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-text="item.title"></v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn icon @click="openDocument(item.title)">
            <v-icon color="grey lighten-1">mdi-file</v-icon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-action>
          <v-btn icon @click="downloadDocument(item.title, item.type)">
            <v-icon color="grey lighten-1">mdi-download</v-icon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-action>
          <v-btn icon @click="deleteDocument(item.title)">
            <v-icon color="grey lighten-1">mdi-delete</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <showDialog ref="dialog" />
    <showMessage ref="snackbar" />
  </v-container>
</template>
<script>
import {BASE_URL} from '../components/constants.js'
import * as basics from '../components/basics.js'
import genericReload from '../components/genericReload';
import showDialog from '../components/Dialog.vue'
import showMessage from '../components/Messages.vue'
export default {
  name: 'Documentacion',
  data: () => ({
    searchDoc: '',
    searchDocuments: [],
    documents: [],
  }),
  components: {
    showDialog,
    showMessage,
  },
  mounted(){
    this.getDocuments();
    setTimeout(() => this.searchDocuments = this.documents);
  },
  computed: {
    filteredItemsDoc() { 
      return this.searchDocuments.filter((item) =>{
          return item.title.match(this.searchDoc) || item.title.toLowerCase().match(this.searchDoc) || item.subtitle.match(this.searchDoc) || item.subtitle.toLowerCase().match(this.searchDoc)
      })
    }
  },
  methods: {
    async newDoc(){
      this.$refs.dialog.setNew("Subir nuevo documento", "", "newDoc").then(async (confirm) => {
        if(confirm.response){          
          let formData = new FormData();
          formData.append('documento', confirm.file);
          const xhr = new XMLHttpRequest();
          xhr.open('POST', `${BASE_URL}/documento`);
          xhr.setRequestHeader('Authorization', 'Bearer '+this.$store.state.auth)
          xhr.responseType = 'json';

          // log response
          xhr.onload = () => {
            switch (xhr.response.status) {
              case "ok":
                this.$refs.snackbar.setNew(true, "Documento subido correctamente", "Ok", "green");
                this.getDocuments();
                break;
              default:
                this.$refs.snackbar.setNew(true, basics.returnError(response.status), "Cerrar", "red");
                break;
            }
          };
          xhr.send(formData);
        }else{
          this.$refs.snackbar.setNew(true, "El documento NO se ha subido", "Cerrar", "red");
        }
      })
    },
    getIconType(type){
      switch(type){
        case "image":
          return {icon: "mdi-file-image", iconClass: "yellow"}
        case "audio":
          return {icon: "mdi-book-music", iconClass: "red"}
        case "video":
          return {icon: "mdi-file-video", iconClass: "purple"}
        case "text":
          return {icon: "mdi-file-document-outline", iconClass: "grey"}
        case "pdf":
          return {icon: "mdi-file-pdf-box", iconClass: "red"}
        case "doc":
          return {icon: "mdi-file-word", iconClass: "blue"}
        case "docx":
          return {icon: "mdi-file-word", iconClass: "blue"}
        case "msexcel":
          return {icon: "mdi-file-excel", iconClass: "green"}
        case "csv":
          return {icon: "mdi-file-excel", iconClass: "green"}
        case "xls":
          return {icon: "mdi-file-excel", iconClass: "green"}
        case "xlsx":
          return {icon: "mdi-file-excel", iconClass: "green"}
        case "ppt":
          return {icon: "mdi-file-powerpoint", iconClass: "orange"}
        case "pptx":
          return {icon: "mdi-file-powerpoint", iconClass: "orange"}
      }
    },
    async getDocuments(){
      this.documents = [];
      setTimeout(() => this.searchDocuments = this.documents);
      const response = await genericReload(this.$store.state.auth, 'documento');
      if (response.status === 200) {
        const json = await response.json();
        if(json.status="ok"){
          let body = json.body;
          for(let i=0; i<body.length; i++){
            let getType = body[i].split(".");
            let type = this.getIconType(getType[getType.length - 1]);
            this.documents.push({type: getType[getType.length - 1], icon: type.icon, iconClass: type.iconClass+" white--text", title: body[i]});
          }
          setTimeout(() => this.searchDocuments = this.documents);
        }
      }
    },
    async openDocument(name){
      let xhr = new XMLHttpRequest();
      xhr.open('GET', BASE_URL+'/documento/download/?name='+name, true);
      xhr.setRequestHeader('Authorization', 'Bearer '+this.$store.state.auth);
      xhr.responseType = 'blob';
      xhr.onload = function () {
        let blob = xhr.response;
        let fileURL = URL.createObjectURL(blob);
        window.open(fileURL, '_blank');
      }
      xhr.onerror = function () {
        switch(xhr.status){
          case 400:
            this.$refs.snackbar.setNew(true, "Error en la aplicación. Consulte a soporte técnico.", "Cerrar", "red");
            break;
          case 401:
            this.$refs.snackbar.setNew(true, "Datos de inicio de sesión erróneos.", "Cerrar", "red");
            break;
          case 500:
            this.$refs.snackbar.setNew(true, "Error en el servidor. Consulte a soporte técnico.", "Cerrar", "red");
            break;
        }
      }
      xhr.send();
    },
    downloadDocument(name, type){
      let xhr = new XMLHttpRequest();
      xhr.open('GET', BASE_URL+'/documento/download/?name='+name, true);
      xhr.setRequestHeader('Authorization', 'Bearer '+this.$store.state.auth);
      xhr.responseType = 'blob';
      xhr.onload = function () {
        //let blob = xhr.response;
        let blob = new Blob([xhr.response], { type: type });
        let fileURL = URL.createObjectURL(blob);
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', name);
        document.body.appendChild(fileLink);
        fileLink.click();
      }
      xhr.onerror = function () {
        switch(xhr.status){
          case 400:
            this.$refs.snackbar.setNew(true, "Error en la aplicación. Consulte a soporte técnico.", "Cerrar", "red");
            break;
          case 401:
            this.$refs.snackbar.setNew(true, "Datos de inicio de sesión erróneos.", "Cerrar", "red");
            break;
          case 500:
            this.$refs.snackbar.setNew(true, "Error en el servidor. Consulte a soporte técnico.", "Cerrar", "red");
            break;
        }
      }
      xhr.send();
    },
    async deleteDocument(name){
      this.$refs.dialog.setNew("¿Estás seguro de llevar a cabo esta operación?", "Se eliminará el documento.").then(async (confirm) => {
        if(confirm.response){
          this.$refs.dialog.setNew("Es necesaria una doble confirmación", "Está intentando llevar a cabo una operación en la que pueden perderse datos irrecuperables. ¿Estás seguro?").then(async (confirm) => {
            if(confirm.response){
              const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+this.$store.state.auth
              };

              const response = await fetch(
                `${BASE_URL}/documento/?name=${name}`,
                {
                  method: 'DELETE',
                  headers: headers,
                }
              );
              switch (response.status) {
                case 200:
                  this.$refs.snackbar.setNew(true, "Documento eliminado correctamente", "Ok", "green");
                  this.getDocuments();
                  break;
                default:
                  this.$refs.snackbar.setNew(true, "ERROR. El documento no se ha eliminado", "Cerrar", "red");
                  break;
              }
            }else{
              this.$refs.snackbar.setNew(true, "El documento NO se ha borrado", "Cerrar", "red");
            }
          })
        }else{
          this.$refs.snackbar.setNew(true, "El documento NO se ha borrado", "Cerrar", "red");
        }
      })
    },
  }
}
</script>
<style scoped>
.v-input{
  margin-right: 1em;
}
</style>